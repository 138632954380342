var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"title":"Please Choose A Report!"}},[_c('v-select',{attrs:{"id":_vm.reports.name,"label":"name","placeholder":"Please Choose A Report","options":_vm.reports.filter(function (x) { return _vm.$can('read', x.resource || 'User'); })},on:{"input":_vm.reportUpdated},model:{value:(_vm.selectedGroupType),callback:function ($$v) {_vm.selectedGroupType=$$v},expression:"selectedGroupType"}})],1)],1)],1),_c('b-row',[(
          _vm.selectedGroupType &&
          _vm.selectedGroupType.Filters.includes('PracticeId')
        )?_c('b-col',{attrs:{"md":"12"}},[_c('b-card',{attrs:{"title":"Please Select A Practice!"}},[_c('v-select',{attrs:{"label":"name","placeholder":"Please Select A Practice","options":_vm.practices,"reduce":function (practice) { return practice.id; }},on:{"input":_vm.practiceUpdated},model:{value:(_vm.selected.PracticeId),callback:function ($$v) {_vm.$set(_vm.selected, "PracticeId", $$v)},expression:"selected.PracticeId"}})],1)],1):_vm._e()],1)],1),(
      _vm.selectionList ||
      (_vm.selectedGroupType && !_vm.selectedGroupType.Filters.includes('PracticeId'))
    )?_c('b-card',{attrs:{"id":"filter-card","title":"Advenced Filters "}},[_c('b-form',[_c('b-row',[(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('DateRange')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Select A Date Range Of Payment Posting","config":{ mode: 'range' }},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('DosRange')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('div',{staticClass:"input-group"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Select A Date Range Of Service","config":{ mode: 'range' }},model:{value:(_vm.dosRange),callback:function ($$v) {_vm.dosRange=$$v},expression:"dosRange"}})],1)])],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('PracticeIds')
          )?_c('b-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"multiple":"","label":"name","placeholder":"Select Practices Or Leave Blank For All Practices","options":_vm.practices,"reduce":function (practice) { return practice.id; }},model:{value:(_vm.selected.PracticeIds),callback:function ($$v) {_vm.$set(_vm.selected, "PracticeIds", $$v)},expression:"selected.PracticeIds"}})],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('MonthlyBillingDay')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"placeholder":"Select Monthly billing Day","label":"name","options":_vm.monthlyBillingDay,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.MonthlyBillingDay),callback:function ($$v) {_vm.$set(_vm.selected, "MonthlyBillingDay", $$v)},expression:"selected.MonthlyBillingDay"}})],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('ReportFor')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"placeholder":"Report For","label":"name","options":_vm.ReportFor,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.ReportFor),callback:function ($$v) {_vm.$set(_vm.selected, "ReportFor", $$v)},expression:"selected.ReportFor"}})],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.providerProfiles &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('ProviderProfileIds')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"multiple":"","get-option-label":_vm.GetCodeAndName,"placeholder":"Select Provider Profile","options":_vm.selectionList.providerProfiles,"reduce":function (providerProfile) { return providerProfile.id; }},model:{value:(_vm.selected.ProviderProfileIds),callback:function ($$v) {_vm.$set(_vm.selected, "ProviderProfileIds", $$v)},expression:"selected.ProviderProfileIds"}})],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.providers &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('ServicingProviderIds')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"label":"name","multiple":"","placeholder":"Select Servicing Provide","options":_vm.selectionList.providers,"reduce":function (servicingProvider) { return servicingProvider.id; }},model:{value:(_vm.selected.ServicingProviderIds),callback:function ($$v) {_vm.$set(_vm.selected, "ServicingProviderIds", $$v)},expression:"selected.ServicingProviderIds"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.providers &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('BillingProviderId')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"label":"name","multiple":"","placeholder":"Select Billing Provider ","options":_vm.selectionList.providers,"reduce":function (billingProviders) { return billingProviders.id; }},model:{value:(_vm.selected.BillingProviderId),callback:function ($$v) {_vm.$set(_vm.selected, "BillingProviderId", $$v)},expression:"selected.BillingProviderId"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.providers &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('PaidProviderIds')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"label":"name","multiple":"","placeholder":"Select Paid Provider ","options":_vm.selectionList.providers,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.PaidProviderIds),callback:function ($$v) {_vm.$set(_vm.selected, "PaidProviderIds", $$v)},expression:"selected.PaidProviderIds"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.modifiers &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('ModifierIds')
          )?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"label":"code","multiple":"","placeholder":"Select Modifiers ","options":_vm.selectionList.modifiers,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.ModifierIds),callback:function ($$v) {_vm.$set(_vm.selected, "ModifierIds", $$v)},expression:"selected.ModifierIds"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.paymentCodes &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('PaymntCodes')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"label":"name","multiple":"","placeholder":"Select Payment Code ","options":_vm.selectionList.paymentCodes,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.PaymntCodes),callback:function ($$v) {_vm.$set(_vm.selected, "PaymntCodes", $$v)},expression:"selected.PaymntCodes"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.locations &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('LocationIds')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"multiple":"","get-option-label":_vm.GetCodeAndName,"placeholder":"Select The Location  ","options":_vm.selectionList.locations,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.LocationIds),callback:function ($$v) {_vm.$set(_vm.selected, "LocationIds", $$v)},expression:"selected.LocationIds"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.paymentSources &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('PaymentSource')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"multiple":"","placeholder":"Select Payment Source ","label":"value","options":_vm.selectionList.paymentSources,"reduce":function (x) { return x.key; }},model:{value:(_vm.selected.PaymentSource),callback:function ($$v) {_vm.$set(_vm.selected, "PaymentSource", $$v)},expression:"selected.PaymentSource"}})],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.practiceInsurances &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('PracticeInsuranceId')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"multiple":"","label":"name","placeholder":"Select Insurance","options":_vm.selectionList.practiceInsurances,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.PracticeInsuranceId),callback:function ($$v) {_vm.$set(_vm.selected, "PracticeInsuranceId", $$v)},expression:"selected.PracticeInsuranceId"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.practiceInsurances &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('IncludePracticeInsuranceIds')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"multiple":"","label":"name","placeholder":"Select Insurances ","options":_vm.selectionList.practiceInsurances,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.IncludePracticeInsuranceIds),callback:function ($$v) {_vm.$set(_vm.selected, "IncludePracticeInsuranceIds", $$v)},expression:"selected.IncludePracticeInsuranceIds"}})],1)],1):_vm._e(),(
            _vm.selectionList &&
            _vm.selectionList.practiceInsurances &&
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes(
              'DontIncludePracticeInsuranceIds'
            )
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"multiple":"","label":"name","placeholder":"Select Insurances Not To Include","options":_vm.selectionList.practiceInsurances,"reduce":function (x) { return x.id; }},model:{value:(_vm.selected.DontIncludePracticeInsuranceIds),callback:function ($$v) {_vm.$set(_vm.selected, "DontIncludePracticeInsuranceIds", $$v)},expression:"selected.DontIncludePracticeInsuranceIds"}})],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('ExportFileType')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"label":"value","placeholder":"Chose Export File Type ","options":_vm.selectionList.expotrFileTypes,"reduce":function (x) { return x.key; }},model:{value:(_vm.selected.ExportFileType),callback:function ($$v) {_vm.$set(_vm.selected, "ExportFileType", $$v)},expression:"selected.ExportFileType"}})],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('CptCodes')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"tags-basic"}},[_vm._v("Enter Cpt Code Then press enter")]),_c('b-form-tags',{staticClass:"mb-2",attrs:{"input-id":"tags-separators","remove-on-delete":"","placeholder":"Enter Cpt Code","separator":" ,;"},model:{value:(_vm.selected.CptCodes),callback:function ($$v) {_vm.$set(_vm.selected, "CptCodes", $$v)},expression:"selected.CptCodes"}})],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('VisitStatus')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('v-select',{attrs:{"label":"value","placeholder":"choose Visit Status","options":_vm.selectionList.visitStatuses,"reduce":function (x) { return x.key; }},model:{value:(_vm.selected.VisitStatus),callback:function ($$v) {_vm.$set(_vm.selected, "VisitStatus", $$v)},expression:"selected.VisitStatus"}})],1)],1):_vm._e()],1),_c('b-row',[(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('IncludeZeroPayments')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":"false","name":"check-button","switch":"","inline":""},model:{value:(_vm.selected.IncludeZeroPayments),callback:function ($$v) {_vm.$set(_vm.selected, "IncludeZeroPayments", $$v)},expression:"selected.IncludeZeroPayments"}},[_vm._v(" Include Zero Payment ")])],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('IsDateOfPosting')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":"false","name":"check-button","switch":"","inline":""},model:{value:(_vm.selected.IsDateOfPosting),callback:function ($$v) {_vm.$set(_vm.selected, "IsDateOfPosting", $$v)},expression:"selected.IsDateOfPosting"}},[_vm._v(" Filter By Date Of Posting ")])],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('BillableOnly')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":"false","name":"check-button","switch":"","inline":""},model:{value:(_vm.selected.BillableOnly),callback:function ($$v) {_vm.$set(_vm.selected, "BillableOnly", $$v)},expression:"selected.BillableOnly"}},[_vm._v(" Billable Only ")])],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('GroupByCheck')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":"false","name":"check-button","switch":"","inline":""},model:{value:(_vm.selected.GroupByCheck),callback:function ($$v) {_vm.$set(_vm.selected, "GroupByCheck", $$v)},expression:"selected.GroupByCheck"}},[_vm._v("Group By Check ")])],1)],1):_vm._e(),(
            _vm.selectedGroupType &&
            _vm.selectedGroupType.Filters.includes('ShowCharts')
          )?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"checked":"false","name":"check-button","switch":"","inline":""},model:{value:(_vm.selected.ShowCharts),callback:function ($$v) {_vm.$set(_vm.selected, "ShowCharts", $$v)},expression:"selected.ShowCharts"}},[_vm._v("Show Charts ")])],1)],1):_vm._e()],1)],1)],1):_vm._e(),(_vm.groups)?_c('b-card',[_c('b-form-group',[_c('v-select',{attrs:{"label":"value","multiple":"","placeholder":"Select File Group ","options":_vm.groups,"selectable":function () { return _vm.selected.FileGroup < 1; },"reduce":function (x) { return x.name; }},model:{value:(_vm.selected.FileGroup),callback:function ($$v) {_vm.$set(_vm.selected, "FileGroup", $$v)},expression:"selected.FileGroup"}})],1)],1):_vm._e(),(_vm.groups)?_c('b-card',{attrs:{"title":_vm.groupTitle}},[_c('b-form-group',[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.selectedGroupType && _vm.selectedGroupType.name == 'Payment Report'
        ),expression:"\n          selectedGroupType && selectedGroupType.name == 'Payment Report'\n        "}],attrs:{"label":"value","multiple":"","placeholder":"Select Grouping Option ","options":_vm.groups,"selectable":function () { return _vm.groupLegnth < 3; },"reduce":function (x) { return x.name; }},model:{value:(_vm.selected.Groups),callback:function ($$v) {_vm.$set(_vm.selected, "Groups", $$v)},expression:"selected.Groups"}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.selectedGroupType && _vm.selectedGroupType.name !== 'Payment Report'
        ),expression:"\n          selectedGroupType && selectedGroupType.name !== 'Payment Report'\n        "}],attrs:{"label":"value","multiple":"","placeholder":"Select Grouping Option ","options":_vm.groups,"selectable":function () { return _vm.groupLegnth < 1; },"reduce":function (x) { return x.name; }},model:{value:(_vm.selected.Groups),callback:function ($$v) {_vm.$set(_vm.selected, "Groups", $$v)},expression:"selected.Groups"}})],1)],1):_vm._e(),(_vm.selected)?_c('b-card',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"disabled":!(
          (_vm.selectedGroupType && _vm.selected.PracticeId) ||
          (_vm.selectedGroupType &&
            !_vm.selectedGroupType.Filters.includes('PracticeId'))
        ),"type":"submit","variant":"primary"},on:{"click":_vm.exportClick}},[_vm._v(" Submit "),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"small":""}}),_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }