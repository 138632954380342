<template>
  <div>
    <b-form>
      <b-row>
        <b-col md="12">
          <b-card title="Please Choose A Report!">
            <v-select
                :id="reports.name"
                v-model="selectedGroupType"
                label="name"
                placeholder="Please Choose A Report"
                :options="
                reports.filter((x) => $can('read', x.resource || 'User'))
              "
                @input="reportUpdated"
            />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
            md="12"
            v-if="
            selectedGroupType &&
            selectedGroupType.Filters.includes('PracticeId')
          "
        >
          <b-card title="Please Select A Practice!">
            <!--        <div>{{ selected }}</div>
            <div>{{ selectedGroupType }}</div> -->
            <v-select
                v-model="selected.PracticeId"
                label="name"
                placeholder="Please Select A Practice"
                :options="practices"
                :reduce="(practice) => practice.id"
                @input="practiceUpdated"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-form>
    <b-card
        v-if="
        selectionList ||
        (selectedGroupType && !selectedGroupType.Filters.includes('PracticeId'))
      "
        id="filter-card"
        title="Advenced Filters "
    >
      <b-form>
        <b-row>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('DateRange')
            "
              md="4"
          >
            <b-form-group>
              <flat-pickr
                  v-model="dateRange"
                  class="form-control"
                  placeholder="Select A Date Range Of Payment Posting"
                  :config="{ mode: 'range' }"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('DosRange')
            "
              md="4"
          >
            <b-form-group>
              <div class="input-group">
              <flat-pickr
                  v-model="dosRange"
                  class="form-control"
                  placeholder="Select A Date Range Of Service"
                  :config="{ mode: 'range' }"
              />
              </div>
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('PracticeIds')
            "
              md="6"
          >
            <v-select
                v-model="selected.PracticeIds"
                multiple
                label="name"
                placeholder="Select Practices Or Leave Blank For All Practices"
                :options="practices"
                :reduce="(practice) => practice.id"
            />
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('MonthlyBillingDay')
            "
              md="4"
          >
            <v-select
                v-model="selected.MonthlyBillingDay"
                placeholder="Select Monthly billing Day"
                label="name"
                :options="monthlyBillingDay"
                :reduce="(x) => x.id"
            />
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('ReportFor')
            "
              md="4"
          >
            <v-select
                v-model="selected.ReportFor"
                placeholder="Report For"
                label="name"
                :options="ReportFor"
                :reduce="(x) => x.id"
            />
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.providerProfiles &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('ProviderProfileIds')
            "
              md="4"
          >
            <v-select
                v-model="selected.ProviderProfileIds"
                multiple
                :get-option-label="GetCodeAndName"
                placeholder="Select Provider Profile"
                :options="selectionList.providerProfiles"
                :reduce="(providerProfile) => providerProfile.id"
            />
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.providers &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('ServicingProviderIds')
            "
              md="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.ServicingProviderIds"
                  label="name"
                  multiple
                  placeholder="Select Servicing Provide"
                  :options="selectionList.providers"
                  :reduce="(servicingProvider) => servicingProvider.id"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.providers &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('BillingProviderId')
            "
              md="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.BillingProviderId"
                  label="name"
                  multiple
                  placeholder="Select Billing Provider "
                  :options="selectionList.providers"
                  :reduce="(billingProviders) => billingProviders.id"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.providers &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('PaidProviderIds')
            "
              md="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.PaidProviderIds"
                  label="name"
                  multiple
                  placeholder="Select Paid Provider "
                  :options="selectionList.providers"
                  :reduce="(x) => x.id"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.modifiers &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('ModifierIds')
            "
              md="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.ModifierIds"
                  label="code"
                  multiple
                  placeholder="Select Modifiers "
                  :options="selectionList.modifiers"
                  :reduce="(x) => x.id"
              />
            </b-form-group>
          </b-col>

          <b-col
              v-if="
              selectionList &&
              selectionList.paymentCodes &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('PaymntCodes')
            "
              cols="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.PaymntCodes"                  
                  label="name"
                  multiple
                  placeholder="Select Payment Code "
                  :options="selectionList.paymentCodes"
                  :reduce="(x) => x.id"
              />
            </b-form-group>
          </b-col>

          <b-col
              v-if="
              selectionList &&
              selectionList.locations &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('LocationIds')
            "
              cols="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.LocationIds"
                  multiple
                  :get-option-label="GetCodeAndName"
                  placeholder="Select The Location  "
                  :options="selectionList.locations"
                  :reduce="(x) => x.id"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.paymentSources &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('PaymentSource')
            "
              cols="4"
          >
            <v-select
                v-model="selected.PaymentSource"
                multiple
                placeholder="Select Payment Source "
                label="value"
                :options="selectionList.paymentSources"
                :reduce="(x) => x.key"
            />
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.practiceInsurances &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('PracticeInsuranceId')
            "
              cols="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.PracticeInsuranceId"
                  multiple
                  label="name"
                  placeholder="Select Insurance"
                  :options="selectionList.practiceInsurances"
                  :reduce="(x) => x.id"
              />
            </b-form-group>
          </b-col>

          <b-col
              v-if="
              selectionList &&
              selectionList.practiceInsurances &&
              selectedGroupType &&
              selectedGroupType.Filters.includes('IncludePracticeInsuranceIds')
            "
              cols="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.IncludePracticeInsuranceIds"
                  multiple
                  label="name"
                  placeholder="Select Insurances "
                  :options="selectionList.practiceInsurances"
                  :reduce="(x) => x.id"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectionList &&
              selectionList.practiceInsurances &&
              selectedGroupType &&
              selectedGroupType.Filters.includes(
                'DontIncludePracticeInsuranceIds'
              )
            "
              cols="4"
          >
            <b-form-group>
              <v-select
                  v-model="selected.DontIncludePracticeInsuranceIds"
                  multiple
                  label="name"
                  placeholder="Select Insurances Not To Include"
                  :options="selectionList.practiceInsurances"
                  :reduce="(x) => x.id"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="4"
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('ExportFileType')
            "
          >
            <b-form-group>
              <v-select
                  v-model="selected.ExportFileType"
                  label="value"
                  placeholder="Chose Export File Type "
                  :options="selectionList.expotrFileTypes"
                  :reduce="(x) => x.key"
              />
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('CptCodes')
            "
              cols="4"
          >
            <b-form-group>
              <label for="tags-basic">Enter Cpt Code Then press enter</label>
              <b-form-tags
                  v-model="selected.CptCodes"
                  input-id="tags-separators"
                  remove-on-delete
                  placeholder="Enter Cpt Code"
                  separator=" ,;"
                  class="mb-2"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="4"
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('VisitStatus')
            "
          >
            <b-form-group>
              <v-select
                  v-model="selected.VisitStatus"
                  label="value"
                  placeholder="choose Visit Status"
                  :options="selectionList.visitStatuses"
                  :reduce="(x) => x.key"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('IncludeZeroPayments')
            "
              cols="4"
          >
            <b-form-group>
              <b-form-checkbox
                  v-model="selected.IncludeZeroPayments"
                  checked="false"
                  name="check-button"
                  switch
                  inline
              >
                Include Zero Payment
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('IsDateOfPosting')
            "
              cols="4"
          >
            <b-form-group>
              <b-form-checkbox
                  v-model="selected.IsDateOfPosting"
                  checked="false"
                  name="check-button"
                  switch
                  inline
              >
                Filter By Date Of Posting
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('BillableOnly')
            "
              cols="4"
          >
            <b-form-group>
              <b-form-checkbox
                  v-model="selected.BillableOnly"
                  checked="false"
                  name="check-button"
                  switch
                  inline
              >
                Billable Only
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('GroupByCheck')
            "
              cols="4"
          >
            <b-form-group>
              <b-form-checkbox
                  v-model="selected.GroupByCheck"
                  checked="false"
                  name="check-button"
                  switch
                  inline
              >Group By Check
              </b-form-checkbox
              >
            </b-form-group>
          </b-col>
          <b-col
              v-if="
              selectedGroupType &&
              selectedGroupType.Filters.includes('ShowCharts')
            "
              cols="4"
          >
            <b-form-group>
              <b-form-checkbox
                  v-model="selected.ShowCharts"
                  checked="false"
                  name="check-button"
                  switch
                  inline
              >Show Charts
              </b-form-checkbox
              >
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card v-if="groups">
      <b-form-group>
        <v-select
            v-model="selected.FileGroup"
            label="value"
            multiple
            placeholder="Select File Group "
            :options="groups"
            :selectable="() => selected.FileGroup < 1"
            :reduce="(x) => x.name"
        />
      </b-form-group>
    </b-card>
    <b-card v-if="groups" :title="groupTitle">
      <b-form-group>
        <v-select
            v-show="
            selectedGroupType && selectedGroupType.name == 'Payment Report'
          "
            v-model="selected.Groups"
            label="value"
            multiple
            placeholder="Select Grouping Option "
            :options="groups"
            :selectable="() => groupLegnth < 3"
            :reduce="(x) => x.name"
        />
        <v-select
            v-show="
            selectedGroupType && selectedGroupType.name !== 'Payment Report'
          "
            v-model="selected.Groups"
            label="value"
            multiple
            placeholder="Select Grouping Option "
            :options="groups"
            :selectable="() => groupLegnth < 1"
            :reduce="(x) => x.name"
        />
      </b-form-group>
    </b-card>
    <b-card v-if="selected">
      <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="
          !(
            (selectedGroupType && selected.PracticeId) ||
            (selectedGroupType &&
              !selectedGroupType.Filters.includes('PracticeId'))
          )
        "
          type="submit"
          variant="primary"
          class="mr-1"
          @click="exportClick"
      >
        Submit
        <b-spinner v-show="loading" small/>
        <span class="sr-only">Loading...</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  // BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BSpinner,
  // BFormDatepicker,
  BCard,
  BFormTags,
  // BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import $ from "jquery";
import vueMultiInput from "vue-multi-input";
import PaymentReportFilters from "../PaymentReportFilters";
import {can} from "@/libs/acl/routeProtection";

export default {
  components: {
    flatPickr,
    BCard,
    // BCardText,
    vSelect,
    BSpinner,
    BRow,
    BCol,
    BFormGroup,
    // BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    vueMultiInput,
    BFormTags,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      dir: "ltr",
      selected: new PaymentReportFilters(),
      dopStart: null,
      dopEnd: null,
      dosStart: null,
      dosEnd: null,
      practices: [],
      dateRange: "",
      dosRange: "",
      selectionList: null,
      groups: null,
      selectedGroupType: null,
      loading: false,
      reports: [
        {
          name: "Payment Report",
          action: "GetPaymentReport",
          groupType: "PaymentGroups",
          Filters: [
            "DateRange",
            "DosRange",
            "PracticeId",
            "LocationIds",
            "IncludeZeroPayments",
            "PaymntCodes",
            "PracticeInsuranceId",
            "PaymentSource",
            "ProviderProfileIds",
            "BillingProviderId",
            "ServicingProviderIds",
            "PaidProviderIds",
            "CptCodes",
            "BillableOnly",
            "ExportFileType",
            "ModifierIds"
          ],
        },
        {
          name: "Visit Report",
          action: "GetVisitReport",
          groupType: "VisitGroups",
          Filters: [
            "DateRange",
            "PracticeId",
            "LocationIds",
            "ProviderProfileIds",
            "CptCodes",
            "ExportFileType",
            "IsDateOfPosting",
            "VisitStatus",
            "IncludePracticeInsuranceIds",
            "DontIncludePracticeInsuranceIds",
          ],
        },
        {
          name: "Cpt Report",
          action: "GetCptReport",
          groupType: "CptGroups",
          Filters: ["DateRange", "PracticeId", "CptCodes", "ExportFileType"],
        },
        {
          name: "Ar Report",
          action: "GetArReport",
          groupType: "ChargeGroups",
          Filters: [
            "DateRange",
            "PracticeId",
            "ExportFileType",
            "IncludePracticeInsuranceIds",
            "DontIncludePracticeInsuranceIds",
          ],
        },
        {
          resource: "Admin",
          name: "Employee Dashboard",
          action: "GetEmployeDeshboard",
          groupType: null,
          Filters: ["DateRange", "ReportFor"],
        },
        {
          resource: "Admin",
          name: "Generate Monthly Reports",
          action: "GenerateMonthlyReports",
          groupType: null,
          Filters: ["PracticeIds", "MonthlyBillingDay"],
          ResponseType: "ResponseModel",
        },
      ],
      multiCol: [
        {
          title: "Enter Cpt Code ",
          type: "input_text",
          key: "CptCodes",
        },
      ],
      multiResult: [],
      ReportFor: [
        {
          name: "User",
          id: 0,
        },
        {
          name: "supervisor",
          id: 1,
        },
        {
          name: "User And supervisor",
          id: 2,
        },
      ],
      monthlyBillingDay: [
        {
          name: "Beginning",
          id: "1",
        },
        {
          name: "Middle",
          id: 16,
        },
      ],
    };
  },
  computed: {
    selectedLegnth() {
      if (this.selected) {
        return this.selected.length;
      }
      return 0;
    },
    groupLegnth() {
      if (this.selected.Groups) {
        return this.selected.Groups.length;
      }
      return 0;
    },
    groupTitle() {
      if (
          this.selectedGroupType &&
          this.selectedGroupType.name === "Payment Report"
      ) {
        return "Add up To 3 Groups In The Order You Want To Have It";
      }
      return "You Can Choose How You Want To Group It";
    },
    can(to) {
      console.log("to: " + to);
      return can("read", to);
    },
  },
  watch: {
    dateRange() {
      if (this.dateRange) {
        const spl = this.dateRange.split(" to ");
        // eslint-disable-next-line
        this.selected.DopStart = spl[0];
        if (spl.length > 1) {
          // eslint-disable-next-line
          this.selected.DopEnd = spl[1];
          this.dateRange = `${this.selected.DopStart} to ${this.selected.DopEnd}`;
        } else {
          // eslint-disable-next-line
          this.selected.DopEnd = spl[0];
          // this.dateRange = this.selected.dateStart + ' to ' + this.selected.dateStart
        }
      } else {
        this.selected.DopStart = null;
        this.selected.DopEnd = null;
      }
    },
    dosRange() {
      if (this.dosRange) {
        const spl = this.dosRange.split(" to ");
        // eslint-disable-next-line
        this.selected.DosStart = spl[0];
        if (spl.length > 1) {
          // eslint-disable-next-line
          this.selected.DosEnd = spl[1];
          this.dosRange = `${this.selected.DosStart} to ${this.selected.DosEnd}`;
        } else {
          // eslint-disable-next-line
          this.selected.DosEnd = spl[0];
          // this.dateRange = this.selected.dateStart + ' to ' + this.selected.dateStart
        }
      } else {
        this.selected.DosStart = null;
        this.selected.DosEnd = null;
      }
    },
  },
  created() {
    const vm = this;
    this.$http
        .get("/ExportChartsApi/ListPractices", {withCredentials: true})
        .then((response) => {
          vm.practices = response.data;
          console.log(vm.practices);
        });
  },
  methods: {
    getResult(val) {
      this.multiResult = val;
      this.selected.CptCodes = this.multiResult.map(({CptCodes}) => CptCodes);
    },
    GetCodeAndName(option) {
      // eslint-disable-next-line
      return option.code + " - " + option.name;
    },
    practiceUpdated() {
      // const myObject = {
      //   b: [1, 2, 3],
      // }
      // const recursiveEncoded = $.param(myObject)
      // const recursiveDecoded = decodeURIComponent($.param(myObject)).replaceAll('[]', '')
      // console.log(recursiveEncoded)
      // console.log(recursiveDecoded)
      const vm = this;
      this.selectionList = null;
      if (this.selected.PracticeId) {
        this.$http
            .get(
                `/ExportChartsApi/GetAllListsForPractice?practiceId=${this.selected.PracticeId}`
            )
            .then((response) => {
              vm.selectionList = response.data;
              console.log(vm.selectionList);
            });
      }
    },
    reportUpdated() {
      this.selected.groups = null;
      const vm = this;
      if (this.selectedGroupType.groupType) {
        this.$http
            .get(
                `/ExportChartsApi/GetGroupingOptions?typeName=${this.selectedGroupType.groupType}`
            )
            .then((response) => {
              vm.groups = response.data;
              this.selected.Groups = null;
              console.log(vm.selectionList);
            });
      } else {
        this.groups = null;
      }
    },
    exportClick() {
      this.loading = true;
      const removeEmptyOrNull = (obj) => {
        Object.keys(obj).forEach(
            (k) =>
                (obj[k] &&
                    typeof obj[k] === "object" &&
                    removeEmptyOrNull(obj[k])) ||
                (!obj[k] && obj[k] !== undefined && delete obj[k])
        );
        return obj;
      };
      console.log(JSON.stringify(this.selected));
      const urlParams = decodeURIComponent(
          $.param(removeEmptyOrNull(this.selected))
      ).replaceAll("[]", "");

      console.log(urlParams);

      const urll = `/ExportChartsApi/${this.selectedGroupType.action}?${urlParams}`;

      const config = {
        responseType:
            this.selectedGroupType.ResponseType &&
            this.selectedGroupType.ResponseType == "ResponseModel"
                ? "json"
                : "blob",
      };
      this.$http
          .get(urll, config)
          .then((response) => {
            console.log(response.data);
            // console.log(response.blob())
            console.log(config.responseType);
            if (config.responseType == "blob") {
              const contentDispositionHeader =
                  response.headers["content-disposition"];
              const contentType = response.headers["content-type"];
              const reportName = contentDispositionHeader
                  .split(";")[1]
                  .trim()
                  .split("=")[1]
                  .split('"')
                  .join("");
              const blob = new Blob([response.data], {type: contentType});
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              document.body.appendChild(a);
              a.href = url;
              a.download = reportName;
              a.click();
              window.URL.revokeObjectURL(url);
              a.remove();
            } else {
              if (response.data.success) {
                this.$toast(response.data.message, {
                  timeout: 20000,
                });
              } else {
                this.$toast.error("Error While Submitting", {
                  timeout: 20000,
                });

                this.$toast.error(`Error Massege ${response.data.message}`, {
                  timeout: 20000,
                });
              }
              console.log(response.data);
            }
          })
          .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
