const ex = class PaymentReportFilters {
    PracticeId = ''

    PracticeIds= []

    UserIds=''

    ReportFor=2

    DopStart = null

    DopEnd = null

    ShowCharts = null

    ShowServicingProviderChart = null

    GroupByCheck = null

    BillableOnly = null

    IncludeZeroPayments = null

    PaymntCodes = null

    PracticeInsuranceId = null

    PaymentSource = null

    BillingProviderId = null

    ServicingProviderIds = null

    PaidProviderIds = null

    ProviderProfileIds = null

    LocationIds = null

    CptCodes = null

    Groups = null

    FileGroup = null
    
    IsDateOfPosting= null

    VisitStatus = null

    ExportFileType = null
}

export default ex
